/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  Modal,
} from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import PointsService from "../../services/points.service";
import { PointsAssignationSchema } from "../../validations/actions.validations";

const FormAssignPoints = (props: any) => {
  const { type, data, handleClose, getData } = props;
  const [state, setState] = useState<any>({
    points: null
  });
  const dispatch: any = useDispatch();
  const PointService = new PointsService();

  useEffect(() => {
    setState({ ...state, ...data, points: 0 });
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = await validate(PointsAssignationSchema, state);

    setState({ ...state, validations });

    if (validations !== false) {
      return;
    }

    dispatch(loading_on());

    try {
        await PointService.assign(state);
        _pushToastMessage({
            type: "success",
            header: "Succeso",
            text: "Socio actualizado con éxito",
        });
        getData();
        handleClose(true);
    } catch (e: any) {
        _handleError(e, "No fue posible actualizar el socio");
        if (e.response?.status === 422) {
            setState({ ...state, validations: e.response.data });
        }
        return;
    } 

    dispatch(loading_off());

    setState({ ...state, validations: {} });
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Puntos</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.points}</div>
            ) : (
              <Input
                value={state.points}
                placeholder={""}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "points", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="points" />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={() => handleClose(false)} appearance="subtle">
              Cancelar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormAssignPoints;

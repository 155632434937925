import api from "./api";
import store from "../redux/store";

class PointsService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }
  
  assign = async (payload: any) => {
    try {
      const response = await api.put(`/partners/assign/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };  

  listActions = async () => {
    try {
      const response = await api.get(`/config`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };  

  saveConfig = async (payload: any) => {
    try {
      return await api.put(`/config/${payload.id}/save-action-config`, payload);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  uploadFile = async (payload: any) => {
    const formData = new FormData();
    console.log("acacacaca", payload)
    formData.append('file', payload.file, 'test.jpg');

    return await api.post('/massive-load', formData, { 
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
  }
}

export default PointsService;

import * as yup from "yup";

export const ProductSchema = yup.object().shape({
  title: yup.string().nullable().required("Este campo es requerido"),
  title_internal: yup.string().nullable().required("Este campo es requerido"),
  description: yup.string().nullable().required("Este campo es requerido"),
  slug: yup.string().nullable().required("Este campo es requerido"),
});

export type ProductType = yup.InferType<typeof ProductSchema>;

import axios from "axios";
import { clear_session } from "../redux/actions/session";
import store from "../redux/store";

class PaginateService {
    constructor() {
        const {session} = store.getState();
        axios.defaults.headers.common["Authorization"] = `Bearer ${session.security_token}`

        axios.interceptors.response.use(
            (response: any) => {
              return response;
            },
            (error: any) => {
              if (error.response && error.response.status === 401) {
                const dispatch: any = store.dispatch;
                dispatch(clear_session());
              }
              return Promise.reject(error);
            }
          );
    }

    get = async (url:any) => {
        try {
            console.log("ACACACCACCA: ", url)
            const response = await axios.get(url);
            return response
        } catch (e: any) {
            return Promise.reject(e)
        }
    }
}

export default PaginateService;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { ReactComponent as ErrorImage } from "../../assets/img/svg/error-404.svg";

const Error404 = () => {
  const navigate: any = useNavigate();
  const { session } = useSelector((state: any) => state);

  useEffect(() => {
    if (!session?.is_logged) {
      navigate("/sign-in");
    } else {
      if (session.profile?.roles?.find((role: any) => role.name === "user")) {
        navigate("/orders");
        return;
      }
      if (session.profile?.roles?.find((role: any) => role.name === "api")) {
        navigate("/documentation");
        return;
      }
    }
  }, []);
  return (
    <div
      className="w-100 d-flex flex-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <ErrorImage style={{ width: "300px", height: "300px" }} />
      <h1>Página no encontrada</h1>
      <Button
        appearance="primary"
        style={{ fontWeight: "bold" }}
        className="px-5 py-3"
        onClick={() => navigate("/sign-in")}
      >
        {" "}
        Volver{" "}
      </Button>
    </div>
  );
};

export default Error404;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ModalComponent from "../../components/Modal/Modal";
import FormProduct from "../../forms/FormProduct";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Header from "./Header";

import { TableComponent } from "./TableComponent";
import Search from "../../components/Search";
import { useDispatch } from "react-redux";
import {
  loading_off,
  loading_on,
  set_loader,
} from "../../redux/actions/loader";
import { _handleError } from "../../helpers/errors";
import ConfirmationModal from "../../components/ConfirmationModal";
import { _pushToastMessage } from "../../helpers";
import Paginate from "../../components/Paginate";
import ProductService from "../../services/product.service";
import { Table } from "rsuite";

interface SortParams {
    key: string,
    sort: string
};

const Products = () => {
  const [state, setState] = useState({
    createProductModal: false,
    updateProductModal: false,
    searchModal: false,
    roles: [],
  });

  const [data, setData] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const dispatch: any = useDispatch();

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  useEffect(() => {
    setChecked(false);
    setIndeterminate(false);
    if (checkedKeys.length === data?.data?.length) {
      setChecked(true);
    } else if (checkedKeys.length === 0) {
      setChecked(false);
    } else if (
      checkedKeys.length > 0 &&
      checkedKeys.length < data?.data?.length
    ) {
      setIndeterminate(true);
    }
  }, [checkedKeys, data]);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async (search: any = null, params: SortParams = { key: 'order', sort: 'desc'}) => {
    dispatch(set_loader({ is_loading: true }));
    try {
      const Product: any = new ProductService();
      const response = await Product.list({ search: search ?? '', ...params });
      setData(response.data);
      setState({
        ...state,
        createProductModal: false,
        updateProductModal: false,
        searchModal: false
      });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e);
    }
  };

  const _handleCheckAll = (value: any, checked: any) => {
    const keys: any = checked ? data?.data?.map((item: any) => item.id) : [];
    setCheckedKeys([...keys]);
  };

  const _handleCheck = (value: any, checked: any) => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item: any) => item !== value);
    setCheckedKeys([...keys]);
  };

  const _toggleCreateProductModal = (getProductData: any) => {
    if (getProductData === true) {
      _getData();
    } else {
      setState({ ...state, createProductModal: !state.createProductModal });
    }
  };

  const _toggleSearchModal = () =>
    setState({ ...state, searchModal: !state.searchModal });

  const _handleSelectChangeGlobal = (payload: any) => {
    switch (payload.option) {
      case 1:
        console.log(payload);
        break;
      case 2:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar los productos seleccionados?",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteProducts(checkedKeys),
        });
        break;
      default:
        console.log(payload);
    }
  };

  const _deleteProducts = async (list: any) => {
    dispatch(loading_on());
    const Product: any = new ProductService();

    try {
      await Product.deleteList(list);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Productos eliminados con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      _getData();
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible eliminar los Productos");
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

  return (
    <DefaultTemplate>
      <ModalComponent
        open={state.createProductModal}
        size="md"
        title="Agregar Producto"
        handleClose={_toggleCreateProductModal}
      >
        <FormProduct type="create" />
      </ModalComponent>

      <ModalComponent
        open={state.searchModal}
        size="md"
        title="Busca rapida"
        handleClose={_toggleSearchModal}
      >
        <Search
          handleSearch={(value: any) => _getData(value)}
          placeholder="Busca por nombre o descripcion"
        />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar producto"
        handleClose={() => confirmation.handleClose()}
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <div className="row mx-0 px-4 py-5 with-header">
        <div className="default-template-box">
          <div className="row">
            <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
              <Header
                handleSelectChange={_handleSelectChangeGlobal}
                handleToggleModal={_toggleCreateProductModal}
                handleSearchModal={_toggleSearchModal}
              />
            </div>
            <div className="col-12 py-5">
              <TableComponent
                indeterminate={indeterminate}
                checked={checked}
                data={data}
                checkedKeys={checkedKeys}
                handleCheckAll={_handleCheckAll}
                handleCheck={_handleCheck}
                roles={state.roles}
                getData={_getData}
              />
            </div>
            <div className="col-12 text-center mb-5">
              <Paginate data={data} setData={setData} />
            </div>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Products;

/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, TagPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import ProductService from "../../services/product.service";
import CategoryService from "../../services/category.service";
import { ProductSchema } from "../../validations/product.validations";

const FormProduct = (props: any) => {
  const { type, data, handleClose } = props;

  const [tags, setTags] = useState([]);
  const [state, setState] = useState<any>({
    brand: "",
    description: "",
    points: "",
    slug: "",
    stock: "",
    title: "",
    title_internal: "",
    validations: {},
  });

  const ProductType = new ProductService();
  const CategoryType = new CategoryService();
  const dispatch: any = useDispatch();
  const [file, setFile] = useState<File>();

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const _getCategories = async () => {
    dispatch(loading_on());
    const response: any = await CategoryType.all();
    setTags(response.data.categories);
    dispatch(loading_off());
  }

  const onPickTag = (values:any, event:any) => {
    if (values == null) {
      values = [];
    }
    state.idcategories = values;
    setState({ ...state });
  }

  useEffect(() => {
    if (type !== "create") {
      setState({ ...state, ...data });
    }
    if (type !== "view") {
      _getCategories();
    }
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    const validations: any = await validate(ProductSchema, state);

    setState({ ...state, validations });

    if (validations) {
      return;
    }

    dispatch(loading_on());

    const payload: any = {
      brand: state.brand,
      description: state.description,
      points: state.points,
      slug: state.slug,
      stock: state.stock,
      title: state.title,
      title_internal: state.title_internal,
      categories: state.idcategories
    };

    try {
    
        if (file) {
            payload.thumbnail = file;
        }

      if (type === "create") {
        payload.state = 1;
        await ProductType.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await ProductType.update(payload);
      }

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Producto ${
          type === "create" ? "creado" : "actualizado"
        } con éxito...`,
      });

      dispatch(loading_off());
      handleClose(true);

      
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Título</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.title}</div>
            ) : (
              <Input
                placeholder="Título"
                value={state.title}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "title", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="level"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Título Interno</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.title_internal}</div>
            ) : (
              <Input
                placeholder="Título Interno"
                value={state.title_internal}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "title_internal", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="title_internal"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Slug</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.slug}</div>
            ) : (
              <Input
                placeholder="Slug"
                value={state.slug}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "slug", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="slug"
              validations={state.validations}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Descripción</label>
            {type && type === "view" ? (
              <div
                className="size-08 ms-2"
                dangerouslySetInnerHTML={{ __html: state.description }}
              />
            ) : (
              <Input
                placeholder="Descripción"
                value={state.description}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "description", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="description"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Marca</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.brand}</div>
            ) : (
              <Input
                placeholder="Marca"
                value={state.brand}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "brand", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="brand"
              validations={state.validations}
            />
          </div>     
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Categorías</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.categories && state.categories.map((category:any, i:any, row:any) => {
                  return (i + 1 === row.length) ?
                    <span> {category.name} </span>
                    :
                    <span> {category.name}, </span>
                })}
              </div>
            ) : (
              <TagPicker 
                data={tags} 
                value={state.idcategories} 
                style={{ width: '100%' }} 
                onChange={onPickTag} 
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="brand"
              validations={state.validations}
            />
          </div>            
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Puntos</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.points}</div>
            ) : (
              <Input
                placeholder="Puntos"
                value={state.points}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "points", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="points"
              validations={state.validations}
            />
          </div>                
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Cantidad</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.stock}</div>
            ) : (
              <Input
                placeholder="Cantidad"
                value={state.stock}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "stock", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="stock"
              validations={state.validations}
            />
          </div>            
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Imagen</label>
            <br/>
            {type && type === "view" ? (
              <img src={process.env.REACT_APP_S3_URL + '/' + state.thumbnail} alt="" style={{maxWidth: "300px", display: "block"}} />
            ) : (<>
                <input type="file" onChange={handleFileChange} />
            </>)}
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormProduct;

import { useEffect, useState } from "react";
import { Table } from 'rsuite';
import PartnerService from "../../../services/partner.service";
import { formatDateFromString } from "../../../helpers/utils";
import Paginate from "../../../components/Paginate";

interface PartnerDetail {
    id: number,
    rut: string,
    numero_socio: number,
    nombre_completo: string,
    telefono: string,
    celular: string,
    direccion: string,
    dpto: string,
    numero: string,
    region: string,
    fecha_ingreso: string,
};

const ViewPartner = (props: any) => {
    const { data } = props;

    console.log(data)
    const [partnerData, setPartnerData] = useState<PartnerDetail>({
        id: 0,
        rut: '',
        numero_socio: 0,
        nombre_completo: '',
        telefono: '',
        celular: '',
        direccion: '',
        dpto: '',
        numero: '',
        region: '',
        fecha_ingreso: '',
    });
    const [pointsLog, setPointsLog] = useState<any>([]);

    useEffect(() => {
        getPartnerDetail();
        getPartnerPointsHistory();
    }, [])

    const getPartnerDetail = async () => {
        const partnerService = new PartnerService();
        const { data:res} = await partnerService.getById(data.id);
        setPartnerData(res.data);
    }

    const getPartnerPointsHistory = async () => {
        const partnerService = new PartnerService();
        const res = await partnerService.getPointsHistory(data.id);
        console.log("PAG: ", res.data)
        setPointsLog(res.data);
    }

    return (
        <>

            <strong>RUT</strong>: { partnerData.rut } <br />
            <strong>Nombre</strong>: { partnerData.nombre_completo } <br />
            <strong>Nro socio</strong>: { partnerData.numero_socio } <br />
            <strong>Tel</strong>: { partnerData.telefono } <br />
            <strong>Celular</strong>: { partnerData.celular } <br />
            <strong>Direccion</strong>: { partnerData.direccion } <br />
            <strong>Nro Depto</strong>: { partnerData.dpto } <br />
            <strong>Nro</strong>: { partnerData.numero } <br />
            <strong>Region</strong>: { partnerData.region } <br />
            <strong>Fecha ingreso</strong>: { partnerData.fecha_ingreso } <br />

            <hr />
            Historial de puntos
            <hr />
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Acumulados</th>
                        <th>Fecha</th>    
                    </tr>
                </thead>

                <tbody>
                    {
                        'data' in pointsLog && pointsLog.data.map ( (item: any, index: number) => (<>
                            <tr key={item.id}>
                                <td>{item.type.display_name}</td>
                                <td>{item.accumulated_points}</td>
                                <td>{ formatDateFromString(item.created_at) }</td>
                            </tr>
                        </>))
                    }
                </tbody>
            </table>

            <div className="col-12 text-center mb-5">
                <Paginate data={pointsLog} setData={setPointsLog} />
            </div>
        </>
    )
};

export default ViewPartner;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  MaskedInput,
  Modal,
  SelectPicker,
  TagPicker,
} from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { phoneMask } from "../../helpers/masks";
import { regexCognito } from "../../helpers/utils";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import RoleService from "../../services/roles.service";
import UserService from "../../services/user.service";
import { ActionSchema } from "../../validations/actions.validations";
import PointsService from "../../services/points.service";

const FormAction = (props: any) => {
  const { type, data, roles, handleClose } = props;
  const [state, setState] = useState<any>({
    slug: '',
    percentage: '',
    points: '',
  });
  const [editableFieldSlug, setEditableFieldSlug] = useState<string>('');
  const dispatch: any = useDispatch();
  const PointService = new PointsService();

  useEffect(() => {
    console.log("Data action: ", data)
    if (data.accumulation_strategy === 'direct') setEditableFieldSlug('direct');
    if (data.accumulation_strategy === 'by_amount') setEditableFieldSlug('percentage');
    if (data.accumulation_strategy === 'arbitrary') setEditableFieldSlug('none');
    setState({ ...state, ...data });
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = await validate(ActionSchema, state);

    setState({ ...state, validations });

    if (validations !== false) {
      return;
    }

    dispatch(loading_on());

    if (type === "update") {
      try {
        await PointService.saveConfig(state);
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "Accion actualizado con éxito",
        });
        handleClose(true);
      } catch (e: any) {
        _handleError(e, "No fue posible actualizar la accion");
        if (e.response?.status === 422) {
          setState({ ...state, validations: e.response.data });
        }
        dispatch(loading_off());
        return;
      }
    }

    setState({ ...state, validations: {} });
    // handleClose();
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
            {
                editableFieldSlug == 'percentage' && <>
                    <div className="col-6 mb-2">
                        <label className="size-08 bold-300 ms-1">Porcentaje</label>
                        { type && type === "view" ? (
                        <div className="size-08 ms-2 capitalize">{state.percentage}</div>
                        ) : (
                        <Input
                            placeholder="Porcentaje"
                            value={state.percentage}
                            onChange={(value: any) =>
                            _handleOnChange({ target: { name: "percentage", value } })
                            }
                        />
                        )}
                        <div className="text-end size-08">
                        <ErrorValidation validations={state.validations} name="percentage" />
                        </div>
                    </div>
                </>
            }
            {
                editableFieldSlug === 'direct' && <>
                    <div className="col-6 mb-2">
                        <label className="size-08 bold-300 ms-1">Points</label>
                        {type && type === "view" ? (
                        <div className="size-08 ms-2 capitalize">{state.points}</div>
                        ) : (
                        <Input
                            value={state.points}
                            placeholder={""}
                            onChange={(value: any) =>
                                _handleOnChange({ target: { name: "points", value } })
                            }
                        />
                        )}
                        <div className="text-end size-08">
                        <ErrorValidation validations={state.validations} name="points" />
                        </div>
                    </div>
                </>
            }
            { editableFieldSlug === 'none'  && 'Asignacion arbitraria' }
        </div>
        {
            (editableFieldSlug === 'direct' || editableFieldSlug === 'percentage') && <>
                <div className="row mt-4">
                    <Modal.Footer>
                        <Button onClick={() => handleClose(false)} appearance="subtle">
                        Cancelar
                        </Button>
                        <Button type="submit" appearance="primary">
                        Confirmar
                        </Button>
                    </Modal.Footer>
                </div>
            </>
        }
      </form>
    </div>
  );
};

export default FormAction;

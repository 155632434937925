import { Fragment, useEffect, useState } from "react";
import { Checkbox, Divider, Table, Tooltip, Whisper } from "rsuite";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import MoreIcon from "@rsuite/icons/More";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeaker } from "../speaker";
import { attributes } from "../Schema";
import ModalComponent from "../../../components/Modal/Modal";
import moment from "moment";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import { _pushToastMessage } from "../../../helpers";
import { _handleError } from "../../../helpers/errors";
import { useSelector } from "react-redux";
import FormAssignPoints from "../../../forms/FormAssignPoints";
import ViewPartner from "../Views/ViewPartner";

export const TableComponent = (props: any) => {
  const {
    data,
    getData,
    searchTerm,
    sortParams,
    indeterminate,
    checked,
    handleCheck,
    handleCheckAll,
    checkedKeys
  } = props;

  const { loader } = useSelector((state: any) => state);

  const dispatch: any = useDispatch();
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [state, setState] = useState<any>({
    selectedData: {},
    showModalAssignPoints: false,
    viewProductModal: false
  });
  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  useEffect(() => {
    const fields: any = { ...attributes };
    const tableHeader: any = Object.keys(fields).map((key: any) => {
      return {
        label: fields[key],
        key: key,
        align: "left",
        alignFlex: "flex-start",
        flexGrow: 1,
      };
    });
    setTableHeader(tableHeader);
  }, []);

    const handleSelectChange = (payload: any) => {
        let selectedData: any = null;
        switch (payload.option) {
        case 4:
            selectedData = data.data.find((item: any) => item.id === payload.id);
            setState({ ...state, selectedData, showModalAssignPoints: true });
            break;        
        default:
            console.log(payload);
        }
    };

    const handleDetails = (id: number) => {
        let selectedData = data.data.find((item: any) => item.id === id);
        setState({ ...state, viewProductModal: true, selectedData });
    }

  const Actions = (props: any) => {
        const { payload } = props;
        const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);

        return (
            <div className="row me-3">
                <div className="col-12">
                    <button
                        className="btn px-0"
                        onClick={() => handleDetails(payload.id)}
                    >
                        <ParagraphIcon />
                    </button>
                    <Divider vertical />
                    <span style={{ cursor: "pointer" }}>
                    <Whisper
                        controlId={payload.id}
                        placement="auto"
                        trigger="click"
                        speaker={(whisper_payload: any, ref: any) =>
                        MoreMenu(whisper_payload, ref, _speaker)
                        }
                    >
                        <MoreIcon />
                    </Whisper>
                    </span>
                </div>
            </div>
        );
    };

  const _toggleUpdateBoxModalAndReload = () => {
    setState({ ...state, showModalAssignPoints: !state.showModalAssignPoints });
    getData(searchTerm, sortParams);
  };

  const handleClose = () => {
    setState({ ...state, showModalAssignPoints: !state.showModalAssignPoints });
  }

  const _toggleViewProductModal = () =>
  setState({ ...state, viewProductModal: !state.viewProductModal });

  return (
    <Fragment>

      <ModalComponent
        open={state.showModalAssignPoints}
        size="xs"
        title={'Asignar puntos'}
        handleClose={handleClose}
      >
        <FormAssignPoints data={state.selectedData} getData={_toggleUpdateBoxModalAndReload} />
      </ModalComponent>

      <ModalComponent
        open={state.viewProductModal}
        size="md"
        title="Detalle socio"
        handleClose={_toggleViewProductModal}
      >
        <ViewPartner data={state.selectedData} />
      </ModalComponent>

      <Table
        data={data.data}
        rowClassName="striped"
        autoHeight
        rowHeight={80}
        loading={loader.is_loading}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        <Table.Column align="center" verticalAlign="middle">
          <Table.HeaderCell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
            <Checkbox
              onChange={handleCheckAll}
              indeterminate={indeterminate}
              checked={checked}
            />
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <Checkbox
                  onChange={handleCheck}
                  value={rowData.id}
                  checked={checkedKeys.some((item: any) => item === rowData.id)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>

        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    case "created_at":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={
                            <Tooltip>
                              {moment(rowData[column.key]).format("DD-MM-YYYY")}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {moment(rowData[column.key]).format("DD-MM-YYYY")}
                          </div>
                        </Whisper>
                      );
                    case "roles":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData.roles[0]?.label}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData.roles[0]?.label}
                          </div>
                        </Whisper>
                      );
                    default:
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData[column.key]}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData[column.key]}
                          </div>
                        </Whisper>
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}

        <Table.Column align="right" flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>{""}</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return <Actions payload={rowData} />;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Fragment>
  );
};

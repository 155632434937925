import { TiPlus, TiThSmall } from "react-icons/ti";
import { ImSearch } from "react-icons/im";
import { Whisper } from "rsuite";
import ButtonIcon from "../../../components/ButtonIcon";

const Header = (props: any) => {
  const { handleSearchModal, handleCreateModal } = props;

  return (
    <>
      <div>Carga masiva de puntos</div>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from 'react'
import DefaultTemplate from "../../templates/DefaultTemplate";
import DashboardService from '../../services/dashboard.service';

const Dashboard = () => {

    const dashboardService = new DashboardService();

    const [statistics, setStatistics] = useState({
        points_total: 0,
        partners_total: 0,
        partners_avg: 0,
    });
    
    useEffect( () => { 
        getData();
    }, []);

    const getData = async () => {
        const res = await dashboardService.list();
        console.log(res.data)
        setStatistics(res.data.data);
    }

    return (
        <DefaultTemplate>
            <div className="row with-header px-5 pt-4 mx-0">
                <div className="default-template-box">
                    <div className="row py-3">
                        <div className="col-12 px-3">
                            <span className="size-09">DASHBOARD</span>

                            <hr />

                            <div className="d-flex">
                                <div className="card m-5">
                                    <div className="card-body">
                                        <h5 className="card-title">Total puntos acumulados</h5>
                                        <p className="card-text">
                                            { statistics.points_total }
                                        </p>
                                    </div>
                                </div>


                                <div className="card m-5">
                                    <div className="card-body">
                                        <h5 className="card-title">Total socios con puntos</h5>
                                        <p className="card-text">
                                            { statistics.partners_total }
                                        </p>
                                    </div>
                                </div>


                                <div className="card m-5">
                                    <div className="card-body">
                                        <h5 className="card-title">Promedio puntos</h5>
                                        <p className="card-text">
                                            { statistics.partners_avg }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </DefaultTemplate>
    )
}

export default Dashboard;
import api from "./api";
import store from "../redux/store";

class ProductService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = '') => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/products${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  swipeOrder = async (type: string, productId: number) => {
    try {
      const response = await api.post(`/products/swipe-order/${productId}?type=${type}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
        let headers = {};

        const formData = new FormData();
        if (payload.thumbnail) {
            formData.append('thumbnail', payload.thumbnail);
            headers = {'Content-Type': 'multipart/form-data'}
        }
        formData.append('brand', payload.brand);
        formData.append('description', payload.description);
        formData.append('points', payload.points);
        formData.append('slug', payload.slug);
        formData.append('stock', payload.stock);
        formData.append('title', payload.title);
        formData.append('title_internal', payload.title_internal);
        const categories = payload.categories;
        console.log("categories", categories);
        for ( const cat of categories) {
            formData.append('categories[]', cat);
        }


        const response = await api.post("/products", formData, headers);

      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    console.log(payload)
    try {

        let headers = {};

        const formData = new FormData();
        if (payload.thumbnail) {
            formData.append('thumbnail', payload.thumbnail);
            headers = {'Content-Type': 'multipart/form-data'}
        }
        formData.append('brand', payload.brand);
        formData.append('description', payload.description);
        formData.append('points', payload.points);
        formData.append('slug', payload.slug);
        formData.append('stock', payload.stock);
        formData.append('title', payload.title);
        formData.append('title_internal', payload.title_internal);
        const categories = payload.categories;
        console.log("categories", categories);
        for ( const cat of categories) {
            formData.append('categories[]', cat);
        }

        return await api.post(`/products/update/${payload.id}`, formData, headers);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    try {
      const response = await api.delete(`/products/${id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteList = async (list: any) => {
    try {
      const response: any = await api.post(`/products/delete-list`, {
        list: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };


}

export default ProductService;

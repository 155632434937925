export default interface IProduct {
  title: string;
  description: string;
  brand: string;
  points: string;
  stock: string;
  created_at: any;
}

export const attributes = {
  title: "Titulo",
  description: "Descripción",
  brand: "Marca",
  points: "Puntos",
  stock: "Stock",
  created_at: "Fecha Creación",
};

import { TiPlus, TiThSmall } from "react-icons/ti";
import { ImSearch } from "react-icons/im";
import { Button, Loader, Nav, Whisper } from "rsuite";
import ButtonIcon from "../../../components/ButtonIcon";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeakerAll } from "../speaker";
import { SelectPicker } from 'rsuite';
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";
import { Tag, TagGroup } from 'rsuite';
import PartnerService from "../../../services/partner.service";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../redux/actions/loader";

const Header = (props: any) => {
    const { 
        handleSearchModal, 
        handleOnSort, 
        handleOnClose,
        handleOnCleanSort, 
        searchTerm 
    }= props;

  const Actions = ({ props }: any) => {

    const { handleSelectChange } = props;
    const _speaker: any = _actionMenuSpeakerAll(handleSelectChange);

    return (
        <Whisper
            placement="auto"
            trigger="click"
            speaker={(whisper_payload: any, ref: any) =>
                MoreMenu(whisper_payload, ref, _speaker)
            }
            >
            <button className="btn">
                <TiThSmall
                style={{ marginTop: "5px", width: "25px", height: "25px" }}
                />
            </button>
            </Whisper>
        );
    };

    const data = [ 
        { label: 'Puntos descendiente', val: 'desc'}, 
        { label: 'Puntos ascendiente', val: 'asc'},
    ].map(
        item => ({ label: item.label, value: item.val })
    );

    const Export = (props: any) => {

        const [link, setLink] = useState<string>('');
        const [loading, setLoading] = useState<boolean>(false);
        
        const getLink = async () => {       
            setLoading(true);     
            try {
                const res = await new PartnerService().exportPartners();
                setLink(res.data.url);
            } catch (e: any) {
                console.log("Error: " , e);
            }
            setLoading(false);
        }

        return (
            <div className="mx-2">
                {
                    link && <>
                        <a href={link} className="mx-5" >Descargar</a>
                    </>
                }
                <Button appearance="primary" href="#" onClick={getLink}>
                    Exportar socios con puntos 
                    { loading && <Loader /> }
                </Button>
            </div>
        )
    }

    return (
        <>
            <div>Socios</div>
            <div className="d-flex align-items-center">
                <Export />
            
                { searchTerm && <>
                    <TagGroup className="mx-3">
                        <Tag closable onClose={handleOnClose}>{searchTerm}</Tag>
                    </TagGroup>
                </>}

                

                <SelectPicker 
                    label={'Ordernar por'}
                    data={data}
                    searchable={false} 
                    onChange={handleOnSort}
                    onClean={handleOnCleanSort}
                    style={{ width: 300 }} />

                <ButtonIcon
                    Icon={ImSearch}
                    label=""
                    width="20px"
                    height="20px"
                    style={{ marginTop: "5px" }}
                    onClick={handleSearchModal}
                />
            </div>
        </>
    );
};

export default Header;

import api from "./api";
import store from "../redux/store";

class PartnerService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  getById = async (id: number) => {
    try {
      return api.get(`/partners/show/${id}`);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  exportPartners = async () => {
    try {
        return api.get(`/partners/export`);
      } catch (e: any) {
        return Promise.reject(e);
    }
  }

  getPointsHistory = async (id: number) => {
    try {
        return api.get(`/partners/points/history/${id}`);
      } catch (e: any) {
        return Promise.reject(e);
    }
  }

  list = async (search: any = '', params: string[] = []) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key] ?? ''}`)
            .join("&");
      }
      const response = await api.get(`/partners${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

}

export default PartnerService;

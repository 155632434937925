import * as yup from "yup";

export const ActionSchema = yup.object().shape({
  percentage: yup.number().required("Este campo es requerido").typeError('Ingresa un numero'),
  points: yup.number().required("Este campo es requerido").typeError('Ingresa un numero')
});

export const PointsAssignationSchema = yup.object().shape({
    points: yup.number().min(1, "Ingresa un valor").required("Este campo es requerido").typeError('Ingresa un numero')
});

export type ActionType = yup.InferType<typeof ActionSchema>;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "../components/Loader";
import { routes } from "../routes/routes";

const RouterWrapper = () => {
  const { session } = useSelector((state: any) => state);
  const [routeList, setRouteList] = useState<any>([]);

  useEffect(() => {
    let routeList: any = [];
    const publicRoutes: any = routes.filter((item: any) =>
      item.permissions?.find((permission: any) => permission === "all")
    );

    routeList = [...publicRoutes];

    if (session.is_logged) {
      const privateRoutes: any = routes.filter((item: any) =>
        item.permissions?.find(
          (permission: any) => permission === session.profile?.roles[0]?.name
        )
      );

      routeList = [...routeList, ...privateRoutes];
    }

    setRouteList([...routeList]);
  }, [session]);

  return (
    <BrowserRouter>
      <Loader />
      <Routes>
        {routeList?.map((route: any) => (
          <Route
            path={route.path}
            element={<route.component />}
            key={route.path}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default RouterWrapper;

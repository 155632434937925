/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modal/Modal";
import Search from "../../components/Search";
import { _pushToastMessage } from "../../helpers";
import { loading_off, loading_on } from "../../redux/actions/loader";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Header from "./Header";
import { _handleError } from "../../helpers/errors";
import ConfirmationModal from "../../components/ConfirmationModal";
import PointsService from "../../services/points.service";
import FormCategory from "../../forms/FormCategory";
import { Uploader, Button, Message } from 'rsuite';

const MassiveLoad = () => {
    const dispatch: any = useDispatch();

    const [file, setFile] = useState<any>(null);
    const [message, setMessage] = useState<any>('');
    const [typeMessage, setTypeMessage] = useState<any>('');
    const uploader = useRef<any>();
    const PointService = new PointsService();
    const onChange = (e: any) => {
        const upload = e.target.files[0];
        setFile(upload);
    }
    const upload = async () => {
        dispatch(loading_on());
        
        try {
            const response = await PointService.uploadFile({file: file});
            setMessage('Carga masiva procesada exitosamente.');
            setTypeMessage('success');
        } catch (e: any) {
            setMessage('Problema al procesar archivo. formato no valido.');
            setTypeMessage('error');
            console.log("Error: ", e);
        }
        setFile(null);
        dispatch(loading_off());
    }

    return (
        <DefaultTemplate>
            <div className="row mx-0 px-4 py-5 with-header">
                <div className="default-template-box">
                    <div className="row">
                    <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
                        <Header />
                    </div>
                    <div className="col-12 py-5">
                    <input
                        type="file"
                        name="csv"
                        onChange={onChange}
                        ref={uploader} />
                    <hr />

                    {
                        message && <>
                            <Message showIcon type={typeMessage}>
                                { message }
                            </Message>
                        </>
                    }
                    <Button
                        onClick={() => {
                            upload();
                        }}
                    >
                        Cargar puntos
                    </Button>
                    </div>
                    </div>
                </div>
            </div>
        </DefaultTemplate>
    );
};

export default MassiveLoad;

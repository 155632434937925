/* eslint-disable no-useless-escape */
export function regexCognito(pass: any) {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  return regex.test(pass);
}

export const formatDateFromString = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};
  

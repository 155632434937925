/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ModalComponent from "../../components/Modal/Modal";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Header from "./Header";

import { TableComponent } from "./TableComponent";
import Search from "../../components/Search";
import { useDispatch } from "react-redux";
import {
  loading_off,
  loading_on,
  set_loader,
} from "../../redux/actions/loader";
import { _handleError } from "../../helpers/errors";
import ConfirmationModal from "../../components/ConfirmationModal";
import { _pushToastMessage } from "../../helpers";
import Paginate from "../../components/Paginate";
import PartnerService from "../../services/partner.service";
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";

interface SortParams {
    key: string,
    sort: string
};

const Partners = () => {
  const [state, setState] = useState({
    createModal: false,
    updateModal: false,
    searchModal: false,
    roles: [],
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState('desc');
  const [sortKey, setSortKey] = useState('id');
  const [sortParams, setSortParams] = useState<SortParams>({
    key: 'id',
    sort: 'desc',
  });
  const [data, setData] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const dispatch: any = useDispatch();

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  useEffect(() => {
    setChecked(false);
    setIndeterminate(false);
    if (checkedKeys.length === data?.data?.length) {
      setChecked(true);
    } else if (checkedKeys.length === 0) {
      setChecked(false);
    } else if (
      checkedKeys.length > 0 &&
      checkedKeys.length < data?.data?.length
    ) {
      setIndeterminate(true);
    }
  }, [checkedKeys, data]);

  useEffect(() => {
    _getData(searchTerm, sortParams);
  }, []);

  const _getData = async (search: any = null, params: SortParams = sortParams) => {
    console.log("calling this method");
    dispatch(set_loader({ is_loading: true }));
    try {
        console.log("Params: ", params)
      const Partner: any = new PartnerService();
      const response = await Partner.list({ search: search, ...params });
      setData(response.data);
      setState({
        ...state,
        createModal: false,
        updateModal: false,
        searchModal: false
      });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e);
    }
  };

  const _handleCheckAll = (value: any, checked: any) => {
    const keys: any = checked ? data?.data?.map((item: any) => item.id) : [];
    setCheckedKeys([...keys]);
  };

  const _handleCheck = (value: any, checked: any) => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item: any) => item !== value);
    setCheckedKeys([...keys]);
  };

    const _toggleCreateModal = (getPartnerData: any) => {
        if (getPartnerData === true) {
            _getData();
        } else {
            setState({ ...state, createModal: !state.createModal });
        }
    };

    const _toggleSearchModal = () => setState({ ...state, searchModal: !state.searchModal });

    const _handleOnSort = (sortValue: ValueType, event: any) => {
        if (!sortValue) sortValue = 'desc';
        const sortFilter = {key: 'points', sort: sortValue};
        _getData(searchTerm, sortFilter);
        setSortParams({key: 'points', sort: sortValue});
    }

    const _handleOnCleanSort = () => {
        const sortFilter = {key: 'id', sort: 'desc'};
        _getData(searchTerm, sortFilter);
        setSortParams(sortFilter);

    }

    const _handleSelectChangeGlobal = (payload: any) => {

    };

    const search = (value: string) => {
        setSearchTerm(value)
        _getData(value)
    }
    
    const _handleOnClose = () => {
        setSearchTerm('');
        _getData();
    }

    return (
        <DefaultTemplate>

            <ModalComponent
                open={state.searchModal}
                size="md"
                title="Busca rapida"
                handleClose={_toggleSearchModal}
            >
                <Search
                    handleSearch={search}
                    placeholder="Busca por nombre o rut"
                />
            </ModalComponent>


            <div className="row mx-0 px-4 py-5 with-header">
                <div className="default-template-box">
                    <div className="row">
                        <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
                            <Header
                            handleSelectChange={_handleSelectChangeGlobal}
                            handleToggleModal={_toggleCreateModal}
                            handleSearchModal={_toggleSearchModal}
                            handleOnSort={_handleOnSort}
                            handleOnClose={_handleOnClose}
                            handleOnCleanSort={_handleOnCleanSort}
                            searchTerm={searchTerm}
                            />
                        </div>
                        <div className="col-12 py-5">
                            <TableComponent
                            indeterminate={indeterminate}
                            checked={checked}
                            data={data}
                            searchTerm={searchTerm}
                            sortParams={sortParams}
                            checkedKeys={checkedKeys}
                            handleCheckAll={_handleCheckAll}
                            handleCheck={_handleCheck}
                            roles={state.roles}
                            getData={_getData}
                            />
                        </div>
                        <div className="col-12 text-center mb-5">
                            <Paginate data={data} setData={setData} />
                        </div>
                    </div>
                </div>
            </div>
        </DefaultTemplate>
    );
};

export default Partners;

import EditIcon from "@rsuite/icons/Edit";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import TrashIcon from "@rsuite/icons/Trash";
import CharacterLockIcon from "@rsuite/icons/CharacterLock";
import { RiLockPasswordLine } from "react-icons/ri";
import store from "../../redux/store";

export const _actionMenuSpeaker = (data: any, _handleOnSelect: any) => {
  const { session } = store.getState();
  const { profile } = session;

  const superAdmin: any = profile.roles?.find(
    (role: any) => role.name === "super-admin"
  );

  const admin: any = profile.roles?.find(
    (role: any) => role.name === "super-admin" || role.name === "company-admin"
  );
  const api: any = data.roles?.find((role: any) => role.name === "api");

  return {
    menu: [
      {
        label: "Editar",
        Icon: EditIcon,
        show: true,
        eventKey: 1,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey, id: data.id }),
    user: data,
  };
};
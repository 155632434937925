import { Fragment, useEffect, useState } from "react";
import { Button, Checkbox, Divider, Table, Tooltip, Whisper } from "rsuite";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import MoreIcon from "@rsuite/icons/More";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeaker } from "../speaker";
import { attributes } from "../Schema";
import ModalComponent from "../../../components/Modal/Modal";
import FormProduct from "../../../forms/FormProduct";
import moment from "moment";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import { _pushToastMessage } from "../../../helpers";
import { _handleError } from "../../../helpers/errors";
import ProductService from "../../../services/product.service";
import { useSelector } from "react-redux";
import SortUpIcon from '@rsuite/icons/SortUp';
import SortDownIcon from '@rsuite/icons/SortDown';

export const TableComponent = (props: any) => {
  const {
    data,
    getData,
    indeterminate,
    checked,
    handleCheck,
    handleCheckAll,
    checkedKeys
  } = props;

  const { loader } = useSelector((state: any) => state);

  const dispatch: any = useDispatch();
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [state, setState] = useState<any>({
    updateProductModal: false,
    viewProductModal: false,
    selectedData: {},
  });
  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  useEffect(() => {
    const fields: any = { ...attributes };
    const tableHeader: any = Object.keys(fields).map((key: any) => {
      return {
        label: fields[key],
        key: key,
        align: "left",
        alignFlex: "flex-start",
        flexGrow: 1,
      };
    });
    setTableHeader(tableHeader);
  }, []);

  const handleSelectChange = (payload: any) => {
    let selectedData: any = null;
    switch (payload.option) {
      case 1:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, updateProductModal: true, selectedData });
        break;
      case 2:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, viewProductModal: true, selectedData });
        break;
      case 3:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar este producto",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteProduct(payload.id),
        });
        break;
      default:
        console.log(payload);
    }
  };

  const _deleteProduct = async (id: any) => {
    dispatch(loading_on());
    const Product: any = new ProductService();
    try {
      await Product.delete(id);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Producto eliminado con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      getData();
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible eliminar este producto");
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

    const _toggleUpdateProductModal = (getProductData: any = false) => {
        setState({ ...state, updateProductModal: !state.updateProductModal });
        if (getProductData) {
            getData();
        }
    };

    const _toggleViewProductModal = () =>
        setState({ ...state, viewProductModal: !state.viewProductModal });
    
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();

    const handleSortColumn = (sortColumn: any, sortType: any) => {
        getData('', {key: sortColumn, sort: sortType});   
        console.log(sortColumn, sortType);
        setTimeout(() => {
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };
  

    const Actions = (props: any) => {
        const { payload } = props;
        const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);

        return (
                <div className="row me-3">
                    <div className="col-12">
                        <button
                        className="btn px-0"
                        onClick={() => handleSelectChange({ option: 2, id: payload.id })}
                        >
                        <ParagraphIcon />
                        </button>
                        <Divider vertical />
                        <span style={{ cursor: "pointer" }}>
                        <Whisper
                            controlId={payload.id}
                            placement="auto"
                            trigger="click"
                            speaker={(whisper_payload: any, ref: any) =>
                            MoreMenu(whisper_payload, ref, _speaker)
                            }
                        >
                            <MoreIcon />
                        </Whisper>
                        </span>
                    </div>
                </div>
        );
    };

    const swipeOrder = async (type: string, rowData: any) => {
        const productId: number = rowData.id;
        const Product = new ProductService();
        
        try {
            const res = await Product.swipeOrder(type, productId);

            if (res.data.status) {
                getData('', { key: 'order', sort: 'desc' } );   
            }

        } catch (e: any) {
            console.log("Error al cambiar orden: ", e);
        }
    }

  return (
    <Fragment>
      <ModalComponent
        open={state.updateProductModal}
        size="md"
        title="Actualizar Producto"
        handleClose={_toggleUpdateProductModal}
      >
        <FormProduct type="update" data={state.selectedData} />
      </ModalComponent>

      <ModalComponent
        open={state.viewProductModal}
        size="sm"
        title="Producto"
        handleClose={_toggleViewProductModal}
      >
        <FormProduct type="view" data={state.selectedData} />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar Producto"
        handleClose={() => confirmation.handleClose()}
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <Table
        data={data.data}
        rowClassName="striped"
        autoHeight
        rowHeight={80}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loader.is_loading}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        <Table.Column align="center" verticalAlign="middle">
          <Table.HeaderCell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <>
                    <Button onClick={() => swipeOrder('down', rowData)}><SortDownIcon /></Button>
                    <Button onClick={() => swipeOrder('up', rowData)}><SortUpIcon /></Button>
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>

        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              sortable={column.key == 'stock' || column.key == 'points'}
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                dataKey={column.key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    case "created_at":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={
                            <Tooltip>
                              {moment(rowData[column.key]).format("DD-MM-YYYY")}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {moment(rowData[column.key]).format("DD-MM-YYYY")}
                          </div>
                        </Whisper>
                      );
                    case "roles":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData.roles[0]?.label}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData.roles[0]?.label}
                          </div>
                        </Whisper>
                      );
                    default:
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData[column.key]}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData[column.key]}
                          </div>
                        </Whisper>
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}

        <Table.Column align="right" flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>{""}</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return <Actions payload={rowData} />;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Fragment>
  );
};

import { Roles } from "./Roles";
import DashboardIcon from "@rsuite/icons/Dashboard";
import PeoplesMapIcon from "@rsuite/icons/PeoplesMap";
import ProjectIcon from "@rsuite/icons/Project";
import { BsCardList } from "react-icons/bs";
import { ReactComponent as Box } from "../assets/img/svg/box-line.svg";
import { ReactComponent as LogOut } from "../assets/img/svg/logout.svg";

import Dashboard from "../pages/DashBoard";
import Products from "../pages/Products";
import Logs from "../pages/Logs";
import SignIn from "../pages/SignIn";
import SignOut from "../pages/SingOut";
import Error404 from "../pages/Error404";
import Category from "../pages/Category";
import PointsActions from "../pages/PointsActions";
import MassiveLoad from "../pages/MassiveLoad";

import Partners from "../pages/Partner";

export const routes: any = [
  {
    component: Dashboard,
    path: "/",
    title: "Dashboard",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
    menu: {
      name: "Dashboard",
      url: "/",
      Icon: DashboardIcon,
    },
  },
  {
    component: Partners,
    path: "/partners",
    title: "Socios",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
    menu: {
      name: "Socios",
      url: "/partners",
      Icon: PeoplesMapIcon,
    },  
  },
  {
    component: Products,
    path: "/products",
    title: "Productos",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
    menu: {
      name: "Productos",
      url: "/products",
      Icon: Box,
    },  
  },
  {
    component: PointsActions,
    path: "/points_actions",
    title: "Acciones puntos",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
    menu: {
      name: "Acciones Puntos",
      url: "/points_actions",
      Icon: Box,
    },  
  },
  {
    component: MassiveLoad,
    path: "/massive",
    title: "Carga masiva",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
    menu: {
      name: "Carga masiva",
      url: "/massive",
      Icon: Box,
    },  
  },
  {
    component: Category,
    path: "/category",
    title: "Categorías",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
    menu: {
      name: "Categorías",
      url: "/category",
      Icon: ProjectIcon,
    },  
  },  
  // {
  //   component: Users,
  //   path: "/users",
  //   title: "Clientes",
  //   exact: true,
  //   permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
  //   menu: {
  //     name: "Clientes",
  //     url: "/users",
  //     Icon: PeoplesMapIcon,
  //   },
  // },
  // {
  //   component: Companies,
  //   path: "/companies",
  //   title: "Empresas",
  //   exact: true,
  //   permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
  //   menu: {
  //     name: "Empresas",
  //     url: "/companies",
  //     Icon: ProjectIcon,
  //   },
  // },
  // {
  //   component: Terminals,
  //   path: "/terminals",
  //   title: "Terminales",
  //   exact: true,
  //   permissions: [Roles.ADMIN],
  //   menu: {
  //     name: "Terminales",
  //     url: "/terminals",
  //     Icon: Terminal,
  //   },
  // },
  // {
  //   component: Boxes,
  //   path: "/boxes",
  //   title: "Cajas",
  //   exact: true,
  //   permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
  //   menu: {
  //     name: "Cajas",
  //     url: "/boxes",
  //     Icon: Box,
  //   },
  // },
  // {
  //   component: DeliveryUsers,
  //   path: "/delivery_users",
  //   title: "Agregar usuario",
  //   exact: true,
  //   permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
  //   menu: {
  //     name: "Agregar usuario",
  //     url: "/delivery_users",
  //     Icon: UserBadgeIcon,
  //   },
  // },
  // {
  //   component: Orders,
  //   path: "/orders",
  //   title: "Órdenes",
  //   exact: true,
  //   permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER],
  //   menu: {
  //     name: "Órdenes",
  //     url: "/orders",
  //     Icon: DocPassIcon,
  //   },
  // },
  // {
  //   component: Mailers,
  //   path: "/mailer",
  //   title: "Template Email",
  //   exact: true,
  //   permissions: [Roles.ADMIN],
  //   menu: {
  //     name: "Template Email",
  //     url: "/mailer",
  //     Icon: HiOutlineMail,
  //   },
  // },
  // {
  //   component: Couriers,
  //   path: "/company-couriers",
  //   title: "Couriers",
  //   exact: true,
  //   permissions: [Roles.ADMIN],
  //   menu: {
  //     name: "Couriers",
  //     url: "/company-couriers",
  //     Icon: BsTruck,
  //   },
  // },
//   {
//     component: Logs,
//     path: "/logs",
//     title: "Logs",
//     exact: true,
//     permissions: [Roles.ADMIN],
//     menu: {
//       name: "Logs",
//       url: "/logs",
//       Icon: BsCardList,
//     },
//   },
  // {
  //   component: Documentation,
  //   path: "/documentation",
  //   title: "Documentación",
  //   exact: false,
  //   permissions: [Roles.ADMIN, Roles.API],
  //   menu: {
  //     name: "Documentación",
  //     url: "/documentation",
  //     Icon: AiOutlineFileWord,
  //   },
  // },
  {
    component: SignOut,
    path: "/sign-out",
    title: "Cerrar",
    exact: true,
    permissions: [Roles.ALL],
    menu: {
      name: "Cerrar",
      url: "/sign-out",
      Icon: LogOut,
    },
  },
  {
    component: SignIn,
    path: "/sign-in",
    title: "Entrar",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: Error404,
    path: "*",
    title: "Error404",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
];
